@font-face {
  font-family: "Suisse Intl";
  src: local("Suisse Intl"),
    url("./styles/fonts/SuisseIntl-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Suisse Intl Bold";
  src: local("Suisse Intl Bold"),
    url("./styles/fonts/SuisseIntl-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Black Slanted";
  src: local("Halvar Breitschrift Black Slanted"),
    url("./styles/fonts/HalvarBreit-BlkSlanted.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Black";
  src: local("Halvar Breitschrift Black"),
    url("./styles/fonts/HalvarBreit-Blk.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Black SuperSlanted";
  src: local("Halvar Breitschrift Black SuperSlanted"),
    url("./styles/fonts/HalvarBreit-BlkSuSlanted.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Light";
  src: local("Halvar Breitschrift Light"),
    url("./styles/fonts/HalvarBreit-Lt.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Hairline SuperSlanted";
  src: local("Halvar Breitschrift Hairline SuperSlanted"),
    url("./styles/fonts/HalvarBreit-HlSuSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Medium";
  src: local("Halvar Breitschrift Medium"),
    url("./styles/fonts/HalvarBreit-Md.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraThin SuperSlanted";
  src: local("Halvar Breitschrift ExtraThin SuperSlanted"),
    url("./styles/fonts/HalvarBreit-XThSuSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Bold";
  src: local("Halvar Breitschrift Bold"),
    url("./styles/fonts/HalvarBreit-Bd.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Medium SuperSlanted";
  src: local("Halvar Breitschrift Medium SuperSlanted"),
    url("./styles/fonts/HalvarBreit-MdSuSlanted.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Hairline Slanted";
  src: local("Halvar Breitschrift Hairline Slanted"),
    url("./styles/fonts/HalvarBreit-HlSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Regular Slanted";
  src: local("Halvar Breitschrift Regular Slanted"),
    url("./styles/fonts/HalvarBreit-RgSlanted.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraBold";
  src: local("Halvar Breitschrift ExtraBold"),
    url("./styles/fonts/HalvarBreit-XBd.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraThin Slanted";
  src: local("Halvar Breitschrift ExtraThin Slanted"),
    url("./styles/fonts/HalvarBreit-XThSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Thin SuperSlanted";
  src: local("Halvar Breitschrift Thin SuperSlanted"),
    url("./styles/fonts/HalvarBreit-ThSuSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraBold SuperSlanted";
  src: local("Halvar Breitschrift ExtraBold SuperSlanted"),
    url("./styles/fonts/HalvarBreit-XBdSuSlanted.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Regular SuperSlanted";
  src: local("Halvar Breitschrift Regular SuperSlanted"),
    url("./styles/fonts/HalvarBreit-RgSuSlanted.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraBold Slanted";
  src: local("Halvar Breitschrift ExtraBold Slanted"),
    url("./styles/fonts/HalvarBreit-XBdSlanted.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Light SuperSlanted";
  src: local("Halvar Breitschrift Light SuperSlanted"),
    url("./styles/fonts/HalvarBreit-LtSuSlanted.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Thin";
  src: local("Halvar Breitschrift Thin"),
    url("./styles/fonts/HalvarBreit-Th.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift ExtraThin";
  src: local("Halvar Breitschrift ExtraThin"),
    url("./styles/fonts/HalvarBreit-XTh.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Bold SuperSlanted";
  src: local("Halvar Breitschrift Bold SuperSlanted"),
    url("./styles/fonts/HalvarBreit-BdSuSlanted.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Thin Slanted";
  src: local("Halvar Breitschrift Thin Slanted"),
    url("./styles/fonts/HalvarBreit-ThSlanted.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Light Slanted";
  src: local("Halvar Breitschrift Light Slanted"),
    url("./styles/fonts/HalvarBreit-LtSlanted.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Hairline";
  src: local("Halvar Breitschrift Hairline"),
    url("./styles/fonts/HalvarBreit-Hl.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Medium Slanted";
  src: local("Halvar Breitschrift Medium Slanted"),
    url("./styles/fonts/HalvarBreit-MdSlanted.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Bold Slanted";
  src: local("Halvar Breitschrift Bold Slanted"),
    url("./styles/fonts/HalvarBreit-BdSlanted.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift Hairline";
  src: local("Halvar Breitschrift Hairline"),
    url("./styles/fonts/HalvarBreit-Hl_1.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Halvar Breitschrift";
  src: local("Halvar Breitschrift Regular"),
    url("./styles/fonts/HalvarBreit-Rg.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

input {
  background: none;
  border: none;
}

input:focus {
  background: white;
  border: 1px solid grey;
}

a {
  color: #ffffff;
  font-family: Suisse Intl;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.clickable {
  cursor: pointer;
}
